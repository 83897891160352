import React, { Component } from 'react';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

class UsersEditor extends Component {
  constructor(props){
    super(props);

    this.state = {
        users: [],
        newUser: {
            email: "",
            name: "",
            role: "viewer",
            notify_messages: 0,
            user_internal_id: 3,
            password: "",
            password_confirmation: "",
        }
    }

    this.reloadState = this.reloadState.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleCreate = this.handleCreate.bind(this);
    
  };

  componentDidMount(){
    this.reloadState(this.props);
  }

  /*componentWillReceiveProps(nextProps){
    if(nextProps !== this.props){
        this.reloadState(nextProps);
    }
  }*/

  reloadState(receivedProps){
    let propsCopy = JSON.parse(JSON.stringify(receivedProps));

    let newState=this.state;
    newState.users= propsCopy.users;
    newState.users.forEach(function (item, index) {
        newState.users[index].password = "";
        newState.users[index].password_confirmation = "";
    });
    this.setState(newState);
  }

  handleChange(e,index,column) {
    let newState=this.state;
    if (index === -1) {
        newState.newUser[column] = e.target.value;
    }else{
        newState.users[index][column] = e.target.value;
    }
    this.setState(newState);
  };

  handleCreate(){
    this.props.makeRequest("POST","user/register",this.state.newUser);
  }
  handleSave(index){
    this.props.makeRequest("POST","user/edit",this.state.users[index]);
  }
  handleDelete(index){
    let deleteConfirmed = window.confirm("¿Borrar usuario " + this.state.users[index].email + "?");
    if (deleteConfirmed === true){
        this.props.makeRequest("POST","user/delete",{email:this.state.users[index].email});
    }
  }


  render() {
    
    return (
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
            <TableRow>
                <TableCell>Id</TableCell>
                <TableCell >email</TableCell>
                <TableCell >Password</TableCell>
                <TableCell >Password confirmation</TableCell>
                <TableCell >Nombre</TableCell>
                <TableCell >Rol</TableCell>
                <TableCell >Notificar email</TableCell>
                <TableCell >Id interno</TableCell>
                <TableCell >Acciones</TableCell>
            </TableRow>
            </TableHead>
            <TableBody>
            {this.state.users.map((user,index) => (
                <TableRow
                    key={user.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                    <TableCell component="th" scope="row">
                        {user.id}
                    </TableCell>
                    <TableCell>{user.email}</TableCell>
                    <TableCell>
                        <TextField 
                            id="standard-basic" 
                            variant="standard" 
                            type="password"
                            value={user.password}
                            onChange={(e) => this.handleChange(e,index,"password")}
                        />
                    </TableCell>
                    <TableCell>
                        <TextField 
                            id="standard-basic" 
                            variant="standard" 
                            type="password"
                            value={user.password_confirmation}
                            onChange={(e) => this.handleChange(e,index,"password_confirmation")}
                        />
                    </TableCell>
                    <TableCell>
                        <TextField 
                            id="standard-basic" 
                            variant="standard" 
                            value={user.name}
                            onChange={(e) => this.handleChange(e,index,"name")}
                        />
                    </TableCell>
                    <TableCell>
                        <Select
                            id="role"
                            value={user.role}
                            onChange={(e) => this.handleChange(e,index,"role")}
                            disabled={user.id === 1}
                        >
                            <MenuItem value={"viewer"}>Viewer</MenuItem>
                            <MenuItem value={"sender"}>Sender</MenuItem>
                            <MenuItem value={"admin"}>Admin</MenuItem>
                        </Select>
                    </TableCell>
                    <TableCell>
                        <Select
                            id="notify_messages"
                            value={user.notify_messages}
                            onChange={(e) => this.handleChange(e,index,"notify_messages")}
                        >
                            <MenuItem value={0}>No</MenuItem>
                            <MenuItem value={1}>Si</MenuItem>
                        </Select>
                    </TableCell>
                    <TableCell>
                        <Select
                            id="user_internal_id"
                            value={user.user_internal_id}
                            onChange={(e) => this.handleChange(e,index,"user_internal_id")}
                        >
                            <MenuItem value={0}>0</MenuItem>
                            <MenuItem value={1}>1</MenuItem>
                            <MenuItem value={2}>2</MenuItem>
                            <MenuItem value={3}>3</MenuItem>
                        </Select>
                    </TableCell>
                    <TableCell>
                        <Button
                            variant="outlined"
                            onClick={() => this.handleSave(index)}
                        >
                            Guardar
                        </Button>
                        {user.id !== 1 && (
                            <Button
                                variant="outlined"
                                onClick={() => this.handleDelete(index)}
                            >
                                    Borrar
                            </Button>
                        )}
                    </TableCell>
                </TableRow>
            ))}
                <TableRow
                    key={0}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                    <TableCell component="th" scope="row">
                        {"Nuevo"}
                    </TableCell>
                    <TableCell>
                        <TextField 
                            id="standard-basic" 
                            variant="standard" 
                            value={this.state.newUser.email}
                            onChange={(e) => this.handleChange(e,-1,"email")}
                        />
                    </TableCell>
                    <TableCell>
                        <TextField 
                            id="standard-basic" 
                            variant="standard" 
                            type="password"
                            value={this.state.newUser.password}
                            onChange={(e) => this.handleChange(e,-1,"password")}
                        />
                    </TableCell>
                    <TableCell>
                        <TextField 
                            id="standard-basic" 
                            variant="standard" 
                            type="password"
                            value={this.state.newUser.password_confimation}
                            onChange={(e) => this.handleChange(e,-1,"password_confirmation")}
                        />
                    </TableCell>
                    <TableCell>
                        <TextField 
                            id="standard-basic" 
                            variant="standard" 
                            value={this.state.newUser.name}
                            onChange={(e) => this.handleChange(e,-1,"name")}
                        />
                    </TableCell>
                    <TableCell>
                        <Select
                            id="role"
                            value={this.state.newUser.role}
                            onChange={(e) => this.handleChange(e,-1,"role")}
                        >
                            <MenuItem value={"admin"}>Admin</MenuItem>
                            <MenuItem value={"sender"}>Sender</MenuItem>
                            <MenuItem value={"viewer"}>Viewer</MenuItem>
                        </Select>
                    </TableCell>
                    <TableCell>
                        <Select
                            id="notify_messages"
                            value={this.state.newUser.notify_messages}
                            onChange={(e) => this.handleChange(e,-1,"notify_messages")}
                        >
                            <MenuItem value={0}>No</MenuItem>
                            <MenuItem value={1}>Si</MenuItem>
                        </Select>
                    </TableCell>
                    <TableCell>
                        <Select
                            id="user_internal_id"
                            value={this.state.newUser.user_internal_id}
                            onChange={(e) => this.handleChange(e,-1,"user_internal_id")}
                        >
                            <MenuItem value={0}>0</MenuItem>
                            <MenuItem value={1}>1</MenuItem>
                            <MenuItem value={2}>2</MenuItem>
                            <MenuItem value={3}>3</MenuItem>
                        </Select>
                    </TableCell>
                    <TableCell>
                        <Button 
                            variant="outlined"
                            onClick={() => this.handleCreate()}
                        >
                            Crear
                        </Button>
                    </TableCell>
                </TableRow>
            </TableBody>
        </Table>
    );
  }
}

export default UsersEditor;
