import React, { Component } from "react";
import {Grid, TextField, Button} from '@material-ui/core';

const LETTERS_PER_MESSAGE = 7;

class EditorPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      outgoingMessage: "",
      letters: 0,
      bytes: 0,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);    
    
  }

  handleChange(e){
    this.setState({
      [e.target.id]: e.target.value,
      letters: e.target.value.length,
      bytes: e.target.value.length,
    });
  }

  handleSubmit() {    
    var requestBody = {};    
    requestBody["message"] = this.state.outgoingMessage;    
    requestBody["unixtime"] = Math.floor(Date.now() / 1000);
    
    this.setState({      
      outgoingMessage: "",      
    });
    
    this.props.makeRequest("POST","browsermessage",requestBody);        
  }

  render() {        

    return (
      <Grid
        container
        spacing={3}
        alignItems="center"
        justify="flex-start"
        direction="row"
        style={{marginTop: "auto"}}
      >
        <Grid item>
          <TextField
            fullWidth
            variant="outlined"
            id="outgoingMessage"
            label="Mensaje"
            value={this.state.outgoingMessage}
            helperText={this.state.letters + " letras. " +  Math.ceil(this.state.letters / LETTERS_PER_MESSAGE) + " mensajes. (" + LETTERS_PER_MESSAGE + " letras por mensaje)"}
            onChange={this.handleChange}
            autoComplete="off"
            autoFocus
            margin="dense"
          />  
        </Grid>
        <Grid item>
          <Button 
            variant="contained" 
            color="primary"
            disabled={this.state.outgoingMessage.length === 0}
            onClick={this.handleSubmit}
          >
            Enviar
          </Button>
        </Grid>  
      </Grid>
    );    
  }
}

export default EditorPanel;