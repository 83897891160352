import React, { Component } from "react";
import { BrowserRouter, Routes, Route} from "react-router-dom";
//import { Grid, } from "semantic-ui-react";
import Login from "./Pages/Login";
import Main from "./Pages/Main";
import {Grid, LinearProgress} from '@material-ui/core';

export default class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      logged: false,
      error: "none",      
      data: "",
      userId: -1,
      username: "",
      email: "",
      role: "",      
      lastRefreshUnix: Date.now(),
      alertText: "",      
      spinnerLogin: false,
      spinnerData: false,
      wrongLogin: false,
      statusIndex: -1,
      usersEditor: false,
    }

    //*****************************************************************************************************************
    this.appVersion = 1;
    this.offline = false;
    //offline params:
    this.loggedOffWhenOffline = false;
    this.offlineUser = "";

    this.apiURL = `${process.env.REACT_APP_API_URL}`;
    //*****************************************************************************************************************
    this.fetchStatusIndexPeriod = 1000; //How often the api is called in milliseconds
    //*****************************************************************************************************************    
    this.fetchStatusIndexBlocked = false;
    this.onGoingFetchStatusIndex = false;    
    this.onGoingFetchData = false;

    this.makeRequest = this.makeRequest.bind(this);
    this.receiveRequest = this.receiveRequest.bind(this);
    this.getCookie = this.getCookie.bind(this);
    this.setCookie = this.setCookie.bind(this);
    this.closeSession = this.closeSession.bind(this);
    this.fetchStatusIndex = this.fetchStatusIndex.bind(this);    
    this.closeAlertText = this.closeAlertText.bind(this);    
    this.setWrongLogin = this.setWrongLogin.bind(this);
    this.setUsersEditor = this.setUsersEditor.bind(this);
  }

  

  componentDidMount() {        
    window.setInterval(this.fetchStatusIndex, this.fetchStatusIndexPeriod); 
    let jwt = this.getCookie("jwt");      
    if (jwt !== "") {                 
      this.makeRequest("POST","user/checktoken",{});
    }
  }
  

  fetchStatusIndex() {    
    if (
      this.onGoingFetchStatusIndex === false &&
      this.onGoingFetchData === false &&
      this.fetchStatusIndexBlocked === false &&
      this.state.logged === true &&
      this.state.alertText === ""
      //&& unixNow - this.state.lastRefreshUnix >= this.fetchStatusIndexPeriod
    ) {      
      this.onGoingFetchStatusIndex = true;
      this.makeRequest("GET","status",{});
    }
  }

  fetchData(){        
    this.makeRequest("GET","data",{});
  }

  closeSession() {
    document.cookie = "jwt=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    this.onGoingFetchStatusIndex = false;    
    this.onGoingFetchData = false;
    this.setState({
      logged: false,
      error: "none",      
      data: {},
      userId: -1,
      username: "",
      email: "",
      role: "",      
      lastRefreshUnix: Date.now(),
      alertText: "",      
      spinnerLogin: false,
      spinnerData: false,
      wrongLogin: false,
      statusIndex: -1,
    });
  }

  makeRequest(httpVerb,partialEndpoint,requestBody) {
    if (this.offline === false) {
      /*
      Example of fetch function. Should I use it?
      fetch("https://ipinfo.io/json")
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        console.log(myJson.ip);
      })
      .catch(function (error) {
        console.log("Error: " + error);
      });
      */

      var componentReference = this;
      var http = new XMLHttpRequest();
      var endpoint = this.apiURL + partialEndpoint;
      let payload = requestBody;

      payload = JSON.stringify(payload);      
      http.open(httpVerb, endpoint, true);
      http.setRequestHeader("Content-type","application/json");
      //add JWT
      let jwt = this.getCookie("jwt");      
      if (jwt !== "") {       
        http.setRequestHeader("Authorization","bearer " + jwt);
      }
      //http.setRequestHeader('Accept-Encoding', 'gzip'); //El browser no permite añadirlo: Refused to set unsafe header "Accept-Encoding"
      http.onreadystatechange = function() {
        //Call a function when the state changes.        
        let acceptedCodes = [200,201,401];        
        if (http.readyState === 4 && acceptedCodes.includes(http.status)) {          
          var resp = JSON.parse(http.responseText);
          let requestData = {
            httpVerb: httpVerb,            
            partialEndpoint: partialEndpoint,            
            unixtime: Math.floor(Date.now()/1000),
            body: payload,
            status: http.status,
          };
          componentReference.receiveRequest(requestData,resp);
        }
      };
      http.send(payload);
      
      if (partialEndpoint === "status"){
        this.onGoingFetchStatusIndex = true;
      }else if (partialEndpoint === "data" || partialEndpoint === "browsermessage"){
        this.onGoingFetchData = true;
        this.setState({spinnerData: true});
      }else if (partialEndpoint === "user/login" 
                || partialEndpoint === "user/checktoken"
                || partialEndpoint === "user/edit"
                || partialEndpoint === "user/delete"
                || partialEndpoint === "user/register"
      ){        
        this.setState({spinnerLogin: true});
      }
    }
  }

  receiveRequest(requestData,resp) {
    if (requestData.partialEndpoint === "status"){
      this.onGoingFetchStatusIndex = false;
      if (resp.statusIndex !== this.state.statusIndex){        
        this.setState({
          statusIndex: resp.statusIndex,
        });
        if (this.state.logged){
          this.fetchData();
        }
      }      
      this.setState({
        lastRefreshUnix: Date.now(),        
      });
    }else if (requestData.partialEndpoint === "data" || requestData.partialEndpoint === "browsermessage"){
      this.setState({spinnerData: false});
      this.onGoingFetchData = false;      
      this.setState({
        data: resp.data,
        statusIndex: resp.statusIndex,
        role: resp.data.user.role
      });
    }else if (requestData.partialEndpoint === "user/login"){      
      this.setState({spinnerLogin: false});
      if (requestData.status === 200){
        this.setCookie("jwt", resp.token.access_token, 24);
        this.setState({
          logged: true,
          userId: resp.user.id,
          username: resp.user.name,
          email: resp.user.email,
          wrongLogin: false,
        });
        this.fetchData();
      }else{
        this.setState({
          logged: false,
          userId: 0,
          username: "",          
          email: "",
          wrongLogin: true,
        });
      }
    }else if (requestData.partialEndpoint === "user/edit" ||
              requestData.partialEndpoint === "user/delete" ||
              requestData.partialEndpoint === "user/register"
    ){
      this.setState({spinnerLogin: false});
    } else if (requestData.partialEndpoint === "user/checktoken"){              
      this.setState({spinnerLogin: false});
      if (requestData.status === 200){
        this.fetchData();
        this.setState({
          logged: true,
          userId: resp.user.id,
          username: resp.user.name,
          email: resp.user.email,
        });
      }else{
        this.setState({
          logged: false,
          userId: 0,
          username: "",          
          email: "",
        });
      }
    }
  }

  getCookie(cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(";");
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) === " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  setCookie(cname, cvalue, exhours) {
    var d = new Date();
    d.setTime(d.getTime() + exhours * 3600 * 1000);
    var expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/"; //sets expiration time
    //document.cookie = cname + "=" + cvalue + ";" + ";path=/";
  }

  closeAlertText() {
    this.setState({ alertText: "" });
  }

  setWrongLogin(value){
    this.setState({ wrongLogin: value });
  }

  setUsersEditor(value){
    this.setState({ usersEditor: value });
  }

  render() {    

    if (this.state.spinnerLogin || this.state.spinnerData){
      return (
        <Grid
          container
          spacing={1}
          direction="column"                  
          justify="center"
          style={{margin: "auto", width: "50%", minHeight: '100vh'}}
        >
          <Grid item>
            <LinearProgress />
          </Grid>
          <Grid item>
            <LinearProgress color="secondary" />  
          </Grid>
        </Grid>
      );
    }
    
    return (
      <BrowserRouter>        
        <Routes>
          <Route path="/" element={
            <Main 
              logged = {this.state.logged}  
              statusIndex = {this.state.statusIndex} 
              data={this.state.data} 
              makeRequest={this.makeRequest}
              closeSession={this.closeSession}
              closeAlertText={this.closeAlertText}
              alertText={this.state.alertText}
              lastRefreshUnix={this.state.lastRefreshUnix}
              username={this.state.username}
              usersEditor={this.state.usersEditor}
              setUsersEditor={this.setUsersEditor}
              role={this.state.role}
            />} 
          />
          <Route path="login" element={
            <Login 
              logged = {this.state.logged}  
              makeRequest={this.makeRequest}
              wrongLogin={this.state.wrongLogin}
              setWrongLogin={this.setWrongLogin}
            />} 
          />
        </Routes>
      </BrowserRouter>
    );
  }

}
