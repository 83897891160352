import React, { Component } from "react";
import TodayIcon from '@material-ui/icons/Today';
import {Grid} from '@material-ui/core';

class StatusPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
    this.helpers = require("../../helpers/helpers");
  }

  render() {     

    if (this.props.data !== undefined){
      return (
        <Grid
          container
          spacing={3}
          alignItems="center"
          justify="flex-start"
          direction="row"
          style={{marginTop: "auto"}}
        >
          <Grid item xs={1}>
            <TodayIcon style={{marginTop:"10px",marginLeft:"10px"}}/>
          </Grid>
          <Grid item xs={4}>
            <span>
              {this.helpers.getDate(this.props.data.daysSinceEpoch)}
            </span>
          
          </Grid>
        </Grid>      
      );}else{
        return "";
      }
  }
}

export default StatusPanel;