import React, { Component } from "react";
import {Grid, Button} from '@material-ui/core';
import "./styles.css";

export default class Header extends Component {
  constructor(props) {
    super(props);
    this.helpers = require("../../helpers/helpers");    
  }

  render() {
    let refreshText = this.helpers.getTimeTextFromUnix(this.props.lastRefreshUnix, true);

    return (
      <Grid        
        container
        spacing={3}
        alignItems="center"
        justify="flex-start"
        direction="row"
        
      >
        <Grid item xs={2}></Grid>
        <Grid item xs={4} style={{paddingTop:"6px"}}>
          <p
            style={{
              textAlign: "center",
              padding: "10px",
              color: "yellow",
              fontSize: "18px",
              fontWeight: "900",
              fontStyle: "italic",
              fontFamily: "Roboto",
            }}
          >
            JUACHAT
          </p>
        </Grid>
        <Grid item xs={3} style={{paddingTop:"5px"}}>
          <p
            style={{
              textAlign: "center",              
              color: "yellow",
              fontSize: "12px",
              fontWeight: "900",
              fontStyle: "italic",
              fontFamily: "Lato,'Helvetica Neue',Arial,Helvetica,sans-serif",
            }}
          >            
            {refreshText}
          </p>
        </Grid>
        <Grid item xs={3} style={{paddingTop:"5px"}}>
          {this.props.role === "admin" && (
            <Button           
              variant={this.props.usersEditor ? "contained" : "outlined"}
              size="small"
              style={{marginRight:"3vw"}}            
              onClick={() => this.props.setUsersEditor(!this.props.usersEditor)}
            >
              Usuarios
            </Button>
          )}
          
          <Button           
            variant="outlined" 
            size="small"
            style={{marginRight:"3vw"}}            
            color="secundary"            
            onClick={() => this.props.closeSession()}
          >
            Cerrar
          </Button>
        </Grid>
      </Grid>
    );
  }
}
