import React, { Component } from "react";
import {Paper, List, ListItem, ListItemAvatar, Avatar, ListItemText, Divider, Typography } from '@material-ui/core';
import DirectionsBoatIcon from '@material-ui/icons/DirectionsBoat';
import ComputerIcon from '@material-ui/icons/Computer';

class MessagesPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
    this.helpers = require("../../helpers/helpers");
    
    this.listRef = React.createRef()
  }  

  componentDidMount () {
    this.scrollToBottom()
  }

  componentDidUpdate () {
    this.scrollToBottom()
  }

  scrollToBottom = () => {    
    this.listRef.current.scrollTo(0,this.listRef.current.scrollHeight);
  }

  render() {
    const MessageItem = ({message}) => {            
      return (
        
        <ListItem key={message.key} style={{ height: '35px'}}>                    
          <ListItemAvatar style={{alignItems: "left"}}>
              <Avatar style={{ height: '30px', width: '30px' }}>
                {message.messageType === "browser" &&
                  <ComputerIcon/>
                }
                {message.messageType === "remote" &&
                  <DirectionsBoatIcon color="primary" />
                }
              </Avatar>
            </ListItemAvatar>                         
          <ListItemText          
            style={{textAlign: "center", maxWidth: "20%"}}
            primary={
              <React.Fragment>
                <Typography
                  component="span"
                  variant="body2"                  
                  color="textPrimary"
                  style={{fontWeight: "bold"}}
                >
                  {this.helpers.getTimeTextFromUnix(message.unixtime*1000,true)}                    
                </Typography>          
              </React.Fragment>
            }    
          />
          <ListItemText
            primary={
              <React.Fragment>
                <Typography
                  component="span"
                  variant="body2"                  
                  color="textPrimary"
                  style={{}}
                >
                  {message.message}
                </Typography>                
              </React.Fragment>
            }    
          />
          <ListItemText
            primary={
              <React.Fragment>
                <Typography
                  component="span"
                  variant="body2"                  
                  color="textPrimary"
                  style={{}}
                >
                  {message.messageType === "browser" && message.sent_to_remote === "0" && "Por enviar"}
                  {message.messageType === "browser" && message.sent_to_remote === "1" && message.confirmed === "0" && "Por confirmar recepción"}
                  {message.messageType === "browser" && message.sent_to_remote === "1" && message.confirmed === "1" && "Recepción confirmada"}
                </Typography>                
              </React.Fragment>
            }    
          />
          
        </ListItem>
      );
    };

    let messageList = [];
    let allMessages = [];        
    
    if (this.props.data !== undefined){    
      if (this.props.data.remoteMessages !== undefined){    
        let r = 0;
        let b = 0;    
        while (this.props.data.browserMessages[b] !== undefined || this.props.data.remoteMessages[r] !== undefined){
          if (this.props.data.browserMessages[b] !== undefined && this.props.data.remoteMessages[r] !== undefined){
            if (this.props.data.browserMessages[b].unixtime < this.props.data.remoteMessages[r].unixtime){            
              allMessages.push(this.props.data.browserMessages[b]);            
              allMessages[allMessages.length-1].messageType = "browser";
              b++;
            }else{
              allMessages.push(this.props.data.remoteMessages[r]);
              allMessages[allMessages.length-1].messageType = "remote";
              r++;
            }
          }else if (this.props.data.browserMessages[b] !== undefined){
            allMessages.push(this.props.data.browserMessages[b]);
            allMessages[allMessages.length-1].messageType = "browser";
            b++;
          }else{
            allMessages.push(this.props.data.remoteMessages[r]);
            allMessages[allMessages.length-1].messageType = "remote";
            r++;
          }
          allMessages[allMessages.length-1].key = allMessages.length-1;
          if (allMessages.length > 1){
            if (allMessages[allMessages.length-2].unixtime !== allMessages[allMessages.length-1].unixtime){
              allMessages[allMessages.length-1].firstFragment = true; 
            }else{
              allMessages[allMessages.length-1].firstFragment = false;
            }
          }else{
            allMessages[allMessages.length-1].firstFragment = true;
          }
        }      
        allMessages.forEach(function(value){
          if (value.firstFragment === true){
            messageList.push(<Divider/>);
          }
          messageList.push(
            <MessageItem message={value}/>
          );
        });
        messageList.push(<Divider/>);
      }  
    }
    
    return (
      <Paper ref={this.listRef}  style={{overflow: 'auto', height: "100%"}}>
        <List>        
          {messageList}
        </List>
      </Paper>
    );    
  }
}

export default MessagesPanel;