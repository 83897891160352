import React, { Component } from "react";
import { Navigate } from "react-router-dom";
//import PropTypes from 'prop-types';
import {Card, Grid, TextField, Button, Typography} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';


const styles = {
      root: {
        minWidth: 275,
      },      
};

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: ""
    };
    this.handleChange = this.handleChange.bind(this);    
    this.handleSubmit = this.handleSubmit.bind(this);        
    this.handleKeypress = this.handleKeypress.bind(this);
  }

  handleSubmit() {    
    var requestBody = {};    
    requestBody["email"] = this.state.email;    
    requestBody["password"] = this.state.password;    
    
    this.setState({      
      password: "",      
    });
    
    this.props.makeRequest("POST","user/login",requestBody);
  }
  
  handleChange(e){    
    this.setState({
      [e.target.id]: e.target.value
    });
    this.props.setWrongLogin(false);
  }

  handleKeypress(e){
    if (e.charCode === 13) {
      this.handleSubmit();
    }
  };

  render() {        
    //const { classes } = this.props;    

    if (this.props.logged) {      
      return (
        <Navigate to="/" replace={true} />
      );
    }

    return (      
      <Grid
          container
          spacing={0}
          direction="column"        
          alignItems="center"
          justify="center"
          style={{minHeight: '100vh'}}
      >
        <Card variant="outlined" style={{borderColor:"#3f51b5", padding:'35px', minWidth: '30vw'}}>
          <Grid
            container
            spacing={3}
            align="center"
            justify="center"
            direction="column"        
          >
            <Grid item>
              <h1> JUACHAT </h1>              
            </Grid>
            <Grid item>
              <h2> ¿Quién eres tú grumete? </h2>
            </Grid>
            <Grid item>
              <TextField
                fullWidth
                variant="outlined"
                id="email"
                label="Email"
                value={this.state.email}
                onChange={this.handleChange}
                onKeyPress={this.handleKeypress}
              />  
            </Grid>
            <Grid item>
              <TextField
                fullWidth
                variant="outlined"
                id="password"
                label="Password"
                type="password"
                autoComplete="current-password"
                value={this.state.password}
                onChange={this.handleChange}
                onKeyPress={this.handleKeypress}
              />
            </Grid>
            {this.props.wrongLogin &&(
              <Grid item>              
                <Typography variant="body1" style={{color:"red"}}>
                  Email y/o contraseña incorrectos.
                </Typography>
              </Grid>
            )}
            <Grid item>
              <Button 
                variant="contained" 
                color="primary"
                disabled={this.state.email === "" || this.state.password === ""}
                onClick={this.handleSubmit}
              >
                Embarcar
              </Button>
            </Grid>            
          </Grid>
        </Card>
      </Grid>
    );    
  }
}

export default withStyles(styles)(Login);