import React, { Component } from 'react';
import { Button, Segment, TransitionablePortal} from 'semantic-ui-react';

class AlertText extends Component {
  /*constructor(props){
    super(props);

  }*/

  componentDidMount(){
    this.reloadState(this.props);
  }

  componentWillReceiveProps(nextProps){
    if(nextProps !== this.props){
      this.reloadState(nextProps)
    }
  }

  reloadState(receivedProps){
    let newState=this.state;
    this.setState(newState);
  }

  render() {


    return (
      <TransitionablePortal  open={this.props.alertText !== "" && this.props.alertText !== undefined} onClose={()=>this.props.closeAlertText()}>
        <Segment style={{ left: '10%', position: 'fixed', top: '30%', zIndex: 1000 }}>
          <p>{this.props.alertText}</p>
          <Button style={{display:"block", margin:"0 auto"}} positive onClick={() => this.props.closeAlertText()}>
            Ok
          </Button>
        </Segment>
      </TransitionablePortal>
    );
  }
}

export default AlertText;
