import React, { Component } from "react";
import { Navigate } from "react-router-dom";
//import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Header from "../../Components/Header";
import AlertText from "../../Components/AlertText"; 
import {Card, Grid} from '@material-ui/core';
import StatusPanel from "../../Components/StatusPanel"; 
import MessagesPanel from "../../Components/MessagesPanel"; 
import EditorPanel from "../../Components/EditorPanel"; 
import UsersEditor from "../../Components/UsersEditor"; 

const styles = {
      root: {
        minWidth: 275,
      },      
};

class Main extends Component {
  constructor(props) {
    super(props);
    this.state = {
      
    };
  }



  render() { 
    if (!this.props.logged) {      
      return (
        <Navigate to="/login" replace={true} />
      );
    }
    
    return (
      <div className="app">
        <div className="header" style={{ height: "45px", width: "100%", background: "#3f51b5"}}>
          <Header
            closeSession={this.props.closeSession}                                    
            logged={this.props.logged}
            lastRefreshUnix={this.props.lastRefreshUnix}
            username={this.props.username}
            usersEditor={this.props.usersEditor}
            setUsersEditor={this.props.setUsersEditor}
            role={this.props.role}
          />
        </div>
        {!this.props.usersEditor && (
          <div className="notHeader">
          <Grid
            container
            spacing={1}
            direction="column"
            alignItems="center"
            justify="space-around"
            style={{minHeight: '100vh-45px'}}
          >
            <Grid item xs={12}>
              <Card style={{width: "95vw",height:"10vh"}}>
                <StatusPanel
                  statusIndex =  {this.props.statusIndex}
                  data={this.props.data}
                />
              </Card>
            </Grid>
            <Grid item xs={12}>
              <Card style={{width: "95vw",height:"55vh"}}>
                <MessagesPanel
                  data={this.props.data}
                />
              </Card>
            </Grid>
            {this.props.role !== "viewer" && (
              <Grid item xs={12}>
                <Card style={{width: "95vw",height:"20vh"}}>
                  <EditorPanel
                    makeRequest={this.props.makeRequest}
                  />
                </Card>
              </Grid>
            )}        
          </Grid>
        </div>
        )}
        
        {this.props.usersEditor && (
          <UsersEditor
            users={this.props.data.users}
            makeRequest={this.props.makeRequest}
          />
        )}

        <AlertText
          alertText={this.props.alertText}
          closeAlertText={this.props.closeAlertText}
        />

      </div>    
    );    
  }
}

export default withStyles(styles)(Main);